<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-sm-10 offset-sm-1">
      <div class="card login-card">
        <div class="card-body loginbody">
          <div class="row ">
            <div class="col-sm-6">
              <div class="image-fluid ">
                <img src="assets/images/Backgrounds/loginimg.png" alt="login" class="login-card-img">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="p-3">
                <div class="card mb-0" style="border-radius: 13px;">
                  <div class="insidecard">
                    <form class="theme-forms theme-form" [formGroup]="passwordForm" >
                      <div class="">
                        <div class="brand-wrapper text-center">
                          <img src="assets/images/logo/zendit-logo.jpg" alt="logo" class="logo">
                        </div>
                        <h6 class="login-card-description text-center">Reset password</h6>
                        <p class="text-center f-11">You can reset your password here
                        </p>
                        
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="control-label">New password</label><span class="asterisk">*</span>
                              <div class="input-group">
                                <input type="password" name="password" id="password"  #password2
                                  class="form-control" placeholder="New password" formControlName="password"  [(ngModel)]="password">
                                <div class="input-group-append" (click)="changeType(password2, 2)">
                                  <span class="input-group-text"><i [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                      style="font-size: 11px;"></i></span>
                                </div>
                              </div>
                              <div
                              *ngIf="(submitted || passwordForm.controls.password.touched)&& passwordForm.controls.password.errors"
                              class="error-notify">
                              <div *ngIf="passwordForm.controls.password.errors.required" class="text-danger mt-1">
                                This field is required.
                              </div>
                              <!-- <div *ngIf="passwordForm.controls.password.errors.incorrect" class="text-danger mt-1">
                               {{passwordForm.controls.password.errors.errmsg}}
                              </div> -->
                            </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="control-label">Confirm password</label><span class="asterisk">*</span>
                              <div class="input-group">
                                <input type="password" name="c_password" id="c_password" 
                                  #password3 class="form-control" formControlName="password_confirmation" placeholder="Confirm password" [(ngModel)]="c_password">
                                <div class="input-group-append" (click)="changeType(password3, 3)">
                                  <span class="input-group-text"><i [ngClass]="toggle3 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                      style="font-size: 11px;"></i></span>
                                </div>
                              </div>
                              <div
                              *ngIf="(submitted || passwordForm.controls.password_confirmation.touched)&& passwordForm.controls.password_confirmation.errors"
                              class="error-notify">
                              <div *ngIf="passwordForm.controls.password_confirmation.errors.required" class="text-danger mt-1">
                                This field is required.
                              </div>
                              <div *ngIf="passwordForm.controls.password_confirmation.errors.incorrect" class="text-danger mt-1">
                               {{passwordForm.controls.password_confirmation.errors.errmsg}}
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>              
                        <div class="text-center mt-2">
                          <button class="btn btn-sm btn-block btncolor"  type="submit"(click)="resetSubmit()" >Submit</button>
                        </div>
                        <!-- <a routerLink="/auth/login" class="forgot-password-link ">Back to login</a> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>