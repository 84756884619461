import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoleApiService } from '../../shared/role-api.service';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	roles?: any

}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	public megaMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : true;
	public fullScreen: boolean = false;
	role: any;
	companyDAta: any;
	settings: any;

	constructor(private router: Router, private roleApiService: RoleApiService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
			});


		}
		let userData = JSON.parse(localStorage.getItem('Zendit_user'));

		
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
	];


	Menus() {
		let companyData = JSON.parse(localStorage.getItem('Zendit_company'));
		const companyDAta = companyData;
		if (companyDAta == null) {
			//console.log('a')
			this.MENUITEMS = [
				{ path: '/company-list', title: 'Company', type: 'link', icon: 'globe', active: false, roles: [1] },
				{ path: '/settings', title: 'Settings', type: 'link',  icon: 'settings', active: false, roles: [7] },
			]
			this.resultSubject.next(this.MENUITEMS); // Emit the result
			return this.MENUITEMS
		} else if (companyDAta != null) {
			this.MENUITEMS = [
				{ path: '/project/project-progressing', icon: 'layers', title: 'Projects', type: 'link', active: false, roles: [4] },
				{ path: '/dashboard', title: 'Activities', icon: 'activity', type: 'link', active: false, roles: [1] },
				// { path: '/view-daily-reports', title: 'Reports', icon: 'file-text', type: 'link', active: false, roles: [5] },
				{ path: '/daily-site-report-new', title: 'Reports', icon: 'file-text', type: 'link', active: false, roles: [5] },

				{ path: '/user', title: 'Users', type: 'link', icon: 'users', active: false, roles: [2] },
				// { path: '/vehicle-list', title: 'Vehicles',  type: 'link', active: false, roles:[2,3,4,5,6,7,8,9,10,11,12,13,14] },
				{ path: '/list-template', title: 'Templates', type: 'link', icon: 'file', active: false, roles: [3] },
				// { path: 'default', title: 'Dashboard', icon: 'airplay', type: 'link', active: false, },
				{ path: '/roles-list', title: 'Roles', type: 'link', icon: 'user', active: false, roles: [6] },
				{ path: '/settings', title: 'Settings', type: 'link', icon: 'settings', active: false, roles: [7] },
			]
			this.resultSubject.next(this.MENUITEMS); // Emit the result
			return this.MENUITEMS
		}
	}
	MEGAMENUITEMS: Menu[] = [
	];
	// Array
	private resultSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	items = this.resultSubject.asObservable();
	// items = new BehaviorSubject<Menu[]>(this.Menus());
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);

}
