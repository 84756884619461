import { Component, PLATFORM_ID, Inject, HostListener, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TourComponent} from '../app/shared/components/tour/tour.component'
import { LoginApiService } from './shared/login-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  // userId: any;
  // tourId: any;
  // userData: any;
  // @ViewChild('openTour') openTour: any;
  // videoURL = 'assets/video/wind-demo.mp4';
  // showVideo:boolean=false
  constructor(@Inject(PLATFORM_ID) private platformId: Object,public router: Router,
  private modalService:NgbModal,private loginService:LoginApiService,private activeroute:ActivatedRoute,
    private loader: LoadingBarService, translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'es', 'fr', 'pt', 'cn', 'ae']);
    }
    // this.activeroute.params.subscribe(params => {
    //   let userData = JSON.parse(localStorage.getItem('Zendit_user'))
    //   this.userId = (userData?.id) ?? 0;
    //  this.tourId = userData?.tour;
    //  this.userData=userData
    // });

    
  }
  ngOnInit() {
   
    // let previousRoute = '';
    // let currentRoute = '';
    //  // Subscribe to the router events to detect navigation changes
    //  this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     const currentRoute = this.router.routerState.snapshot.url;
    //     //console.log(currentRoute)
    //     const excludedRoutes = ['/auth/login', '/forgot-password', '/reset-password'];
    //     const isExcludedRoute = excludedRoutes.some((excludedRoute) => currentRoute.startsWith(excludedRoute));
    //     //console.log(this.userData);

    //     // if (this.userData?.tour === 0 && !isExcludedRoute) {
    //     //   setTimeout(() => {
    //     //     this.modalService.open(this.addCustomer, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
    //     //   }, 500);
    //     // }
    //        setTimeout(() => {
    //         this.modalService.open(this.openTour, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
    //     }, 500);
        
    //   }
    // });
    // if(this.tourId === 0){
    //   setTimeout(() => {
    //   }, 500);
     
    //   // this.showTour(this.addCustomer);
    // } 

    // this.router.navigate(['auth/login']);
  }
  // skiptour(){
  //   this.loginService.skipTour(this.userId).subscribe(res =>{
  //     this.modalService.dismissAll();
  //     let userData = JSON.parse(localStorage.getItem('Zendit_user'))
  //      userData.tour=1
  //      //console.log(userData)
  //      localStorage.setItem('Zendit_user', JSON.stringify(userData));

  //   })
  // }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    event.preventDefault();
    this.moveToNextField();
  }
  
  moveToNextField() {
    const formElements = Array.from(document.querySelectorAll('input, select, textarea'));
    const activeElement = document.activeElement as HTMLElement;
    const currentIndex = formElements.findIndex(element => element === activeElement);
    ////console.log(currentIndex, '<', formElements.length - 1)
    if (currentIndex !== -1 && currentIndex < formElements.length - 1) {
      const nextElement = formElements[currentIndex + 1] as HTMLElement;
      if (nextElement.tagName === 'INPUT' || nextElement.tagName === 'TEXTAREA') {
        (nextElement as HTMLInputElement | HTMLTextAreaElement).focus();
      } else if (nextElement.tagName === 'SELECT') {
        (nextElement as HTMLSelectElement).focus();
      } else {
        nextElement.focus();
      }
    }
  }
  // playVideo(){
  //   this.showVideo = true;
  // }
  
}
