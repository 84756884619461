<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
        <div class="welcome_header" style="background-color: #ffffff00;">
            <div class="header">
                <img src="assets/images/logo/zendit-logo.png" alt="login" class="logo_width">
            </div>
        </div>
        <div class="col-sm-6 offset-sm-3">
            <div class="row">
                <div class="col-sm-12">
                    <div class="p6_rem">
                        <h1 class="welcome_heading text-center">Welcome to Zendit</h1>
                        <p class="welcome_para">To get started, please sign in</p>
                        <form action="">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label class="control-label">Email address</label>
                                    <input type="email" class="form-control" placeholder="Email address">
                                </div>
                            </div>
                            <div class="text-center mt-3">
                                <button class="btn btn-sm btn-block btnnew mb-3"  type="submit"><span>Continue</span>
                                </button>
                            </div>
                            <div class="text-center mt-3">
                                <div class="have_account">Don't have an account? <span class="sign_up">Sign up</span></div>
                            </div>
                            <div class="or_border mt-3">
                                <span style="padding-left: 5px;padding-right: 5px;">Or</span>
                            </div>
                            <button type="submit" class="btn-google btn btn-sm btn-block btn-border mt-3">
                                <span class="img_google"></span>
                                <span style="color: #263032;">Continue with Google</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="welcome_footer">
            <div class="footer">
                <p class="">Terms and Conditions <span style="color: #263032;font-size: 8px;"><i aria-hidden="true" class="fa fa-circle mr-2 ml-2"></i></span> Privacy Policy</p>
            </div>
        </div>
    </div>    
</main>
