<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="welcome_header" style="background-color: #ffffff00;">
                    <!-- <div class="header">
                        <img src="assets/images/logo/zendit-logo.png" alt="login" class="logo_width">
                    </div> -->
                </div>
                <div class="col-sm-3 offset-sm-4">
                    <div class="p1_rem">
                        <h1 class="welcome_heading">Reset your password</h1>
                        <p class="welcome_para">Type in your registered email address to reset password</p>
                        <form action="" [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <app-floating-label label="Email address *" [control]="forgotForm.get('email')">
                                    </app-floating-label>
                                    <div
                                    *ngIf="(submitted || forgotForm.controls.email.touched)&& forgotForm.controls.email.errors"
                                    class="error-notify">
                                    <div *ngIf="forgotForm.controls.email.errors.required" class="text-danger mt-1">
                                      This field is required.
                                    </div>
                                    <div *ngIf="forgotForm.controls.email.errors.email" class="text-danger mt-1">
                                      Invalid email format.
                                    </div>
                                    <div *ngIf="forgotForm.controls.email.errors.incorrect" class="text-danger mt-1">
                                     {{forgotForm.controls.email.errors.errmsg}}
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="row text-center mt-3">
                                <div class="col-sm-5">
                                    <button class="btn btn-sm btn-block btnnew mb-3"  type="submit"><span style="padding-right: 10px;text-transform: uppercase;">Next</span><i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="text-center mt-64">
                                <button class="btn btn-sm btn-block btn-black mb-3" type="button" routerLink="/auth/login"  ><span style="padding-right: 10px;text-transform: uppercase;">BACK to login</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="welcome_footer">
                        <div class="footer">
                             
                            <p class="">Terms and Conditions <span style="color: #263032;font-size: 8px;"><i aria-hidden="true" class="fa fa-circle mr-2 ml-2"></i></span> Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</main>


