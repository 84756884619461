<div class="theme-colors">
    <div class=" pb-0 pl-4 pt-4">
          <div class='icon-div-import'>
           <i class=""></i>
          </div>
          <Label for="radiusRadio">This is a testing server. Please don&apos;t upload original data as we will clear this database occasionally.</Label>
      </div>
      <a
      type='button'
      class="theme-button"
      >Important
    </a>
</div>
