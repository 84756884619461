<main class="d-flex align-items-center min-vh-100 py-3 py-md-0 bg_color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-5">
                <div class="welcome_header" style="background-color: #ffffff00;">
                    <!-- <div class="header">
                        <img src="assets/images/logo/zendit-logo.png" alt="login" class="logo_width">
                    </div> -->
                </div>
                <div class="col-sm-8 offset-sm-2">
                    <div class="p1_rem">
                        <h1 class="welcome_heading mb-3">Sign in</h1>
                        <!-- <p class="welcome_para">To get started, please sign in</p> -->
                        <form action="" [formGroup]="loginForm">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <div class=" error-notify"
                                        *ngIf="(submitted) && (loginForm.controls.email.errors || loginForm.controls.password.errors)">
                                        <div>
                                            <div class="login-alert"
                                                *ngIf="loginForm.controls?.email?.errors?.email || loginForm.controls?.email?.errors?.incorrect || loginForm.controls?.password?.errors?.incorrect">
                                                <p class="text-white mb-0"><i class="fa fa-exclamation-circle mr-2 f-13"
                                                        aria-hidden="true"></i>Login credentials are incorrect.</p>
                                            </div>
                                        </div>
                                        <!-- <div  *ngIf="loginForm.controls.password.errors">
                                        <div class="login-alert" *ngIf="loginForm.controls.password.errors.incorrect">
                                          <p class="text-white" ><i class="fa fa-exclamation-circle mr-2 f-13" aria-hidden="true"></i>Login credentials are incorrect.</p>
                                        </div>
                                      </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <app-floating-label label="Email address *" [control]="loginForm.get('email')">
                                    </app-floating-label>
                                    <div *ngIf="(submitted || loginForm.controls.email.touched)&& loginForm.controls.email.errors"
                                        class="error-notify">
                                        <div *ngIf="loginForm.controls.email.errors.required" class="text-danger mt-1">
                                            This field is required.
                                        </div>
                                        <!-- <div *ngIf="loginForm.controls.email.errors.email" class="text-danger mt-1">
                                Invalid email format.
                              </div>
                              <div *ngIf="loginForm.controls.email.errors.incorrect" class="text-danger mt-1">
                                {{loginForm.controls.email.errors.errmsg}}
                              </div> -->

                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <app-floating-label label="Password *" [control]="loginForm.get('password')">
                                    </app-floating-label>

                                    <div
                                    *ngIf="(submitted || loginForm.controls.password.touched)&& loginForm.controls.password.errors"
                                    class="error-notify">
                                    <div *ngIf="loginForm.controls.password.errors.required" class="text-danger mt-1">
                                      This field is required.
                                    </div>
                                    <!-- <div *ngIf="loginForm.controls.password.errors.incorrect" class="text-danger mt-1">
                                     {{loginForm.controls.password.errors.errmsg}}
                                    </div> -->
                                  </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-5 col-md-12 col-sm-12">
                                    <div class="text-center">
                                        <button class="btn  btn-block btnnew mb-3" type="submit"
                                            (click)="login()"><span style="padding-right: 10px;">LOGIN</span><i
                                                class="fa fa-arrow-right" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-lg-7 col-md-12 col-sm-12">
                                    <div class="text-center">
                                        <button class="btn forgot_pswd mb-3 p_fp" type="submit" routerLink="/forgot-password"><span class="">Forgot
                                                your password?</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center mt-3">
                                <button class="btn btn-sm btn-block btn-black mb-3" type="submit" style="padding-right: 10px;text-transform: uppercase;position: absolute;" routerLink="/sign-up-new">create new account
                                </button>
                                <!-- <button class="beta">Beta</button> -->
                            </div>
                            <!-- <div class="text-center mt-3">
                                <div class="have_account">Don't have an account? <span class="sign_up">Sign up</span></div>
                            </div> -->
                            <!-- <div class="or_border mt-3" *ngIf="notvisible">
                                <span style="padding-left: 5px;padding-right: 5px;">Or</span>
                            </div> -->
                            <!-- <button type="submit" class="btn-google btn btn-sm btn-block btn-border mt-3" *ngIf="notvisible">
                                <span class="img_google"></span>
                                <span style="color: #263032;">Continue with Google</span>
                            </button> -->
                        </form>
                    </div>
                    <div class="welcome_footer">
                        <div class="footer">
                            <p class="">Terms and Conditions <span style="color: #263032;font-size: 8px;"><i
                                        aria-hidden="true" class="fa fa-circle mr-2 ml-2"></i></span> Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="col-sm-10 offset-sm-1 a_center">
                    <img src="assets/images/logo/zendit-logo__1.png" alt="login" class="logo_width">
                </div>
            </div>
        </div>
    </div>
</main>