import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { LoginApiService } from 'src/app/shared/login-api.service';
import { ToastrService } from 'ngx-toastr';


type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-forgot-pswd',
  templateUrl: './forgot-pswd.component.html',
  styleUrls: ['./forgot-pswd.component.scss']
})
export class ForgotPswdComponent implements OnInit {

  password: string = '';
  c_password: string = '';
  toggle1: boolean = false;
  toggle2: boolean = false;
  toggle3: boolean = false;
  toggle4: boolean = false;


  public newUser = false;
  public signup: boolean;
  public user: firebase.User;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;
  public forgotForm: FormGroup;
  submitted = false



  constructor(public authService: AuthService, private fb: FormBuilder,
    public loginService: LoginApiService,
    public toastr: ToastrService, public router: Router) {

    this.forgotForm = fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
  }


  changeType(input_field_password: any, num: any) {
    if (input_field_password.type == "password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if (num == 1)
      this.toggle1 = !this.toggle1;
    else
      this.toggle2 = !this.toggle2;
  }

  forgotFormSubmit() {
    this.submitted = true
    if (this.forgotForm.valid) {
      this.loginService.postForgot(this.forgotForm.value)
        .subscribe((res: any) => {
          // ////console.log(res)
          let message = res?.message
          if (res?.token) {
            this.toastr.success(message, ' ', {
              timeOut: 3000,
            });
          } else {
            this.toastr.warning(message, ' ', {
              timeOut: 3000,
            });
          }


          //this.forgotForm.reset()
          this.router.navigate(['auth/login'])
          //this.responseHandler(res);
        },
          error => {
            ////console.log(error)
            let errmsg = (error?.error?.errors?.email);
            this.forgotForm.controls['email']
              .setErrors({ 'incorrect': true, 'errmsg': errmsg })

          })

      // this.router.navigate(['/auth/login'])
    }
  }

  public toggle() {
    this.signup = !this.signup;
  }


}
