import { Component, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { CheckRoleService } from '../../services/check-role.service';

import { LayoutService } from '../../services/layout.service';
import { DataServiceService } from '../../../data-service.service';
import { RoleApiService } from '../../../shared/role-api.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  role: any;
  settings: any;
  result: any;

  constructor(private router: Router, public navServices: NavService,
    private roleApiService: RoleApiService, @Inject(DOCUMENT) private document: Document,
    public checkRoleService: CheckRoleService,
    public layout: LayoutService, public dataService: DataServiceService) {
    this.checkRoleService.nullifyMenuItems();
    this.checkRoleService.result$.subscribe((result) => {
      this.result = result;
    });

    this.navServices.Menus();
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems?.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
    this.dataService.isAnimationDone = false;
    setTimeout(() => this.dataService.isAnimationDone = true, 1000);
  }
  onResize2() {
    this.dataService.isAnimationDone = false;
    setTimeout(() => this.dataService.isAnimationDone = true, 400);
  }
  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.onResize2();
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      // ////console.log(item)
      if ((menuItem !== item)) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }
  checkExistRole(usertype, rolearray) {
    if (this.result?.role?.role == 'Super Admin') {
      return true
    } else if (this.result?.role?.role == 'Company') {
      return true
    } else {
      if (usertype == 2) {

      } else {
        if (this.result?.settings?.length > 0) {
          if (rolearray) {
            let roleaccess = this.result?.settings.some(ai => rolearray == ai.menu_id);
            if (roleaccess != true) {
              return false
            } else {
              return true
            }
          }
        }

      }
    }





  }


  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }



}
