import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-floating-label',
  templateUrl: './floating-label.component.html',
  styleUrls: ['./floating-label.component.scss']
})
export class FloatingLabelComponent implements OnInit {
  @Input() label: string = '';
  @Input() control: FormControl = new FormControl();
  showValidation:boolean = false;

  inputValue: string = '';
  isInputFocused: boolean = false;

  constructor() { }
 
  ngOnInit(): void {
    console.log(this.control);
    if (this.label.includes('*')) {
      this.label = this.label.replace('*', '');
      this.showValidation = true;
    } 
  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }
 
}
