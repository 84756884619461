import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginApiService } from 'src/app/shared/login-api.service';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss']
})
export class AccountVerificationComponent implements OnInit {
  display: string;
  over: string;
  verificationForm: FormGroup;
  submitted = false;
  adminData: any;
  id: number;
  status: number;
  phone: any

  constructor(public formBuilder: FormBuilder, public loginApi: LoginApiService, public router: Router, public toasterService: ToastrService) {
    this.verificationForm = this.formBuilder.group({
      otp: [null, Validators.required]
    })
  }

  ngOnInit() {
    // this.timer(5);
    let userData = JSON.parse(localStorage.getItem('findmyway'));
    this.adminData = userData;
    this.id = userData.id;
    this.status = userData.status;
    this.phone = userData.phone_number;


  }



  onSubmit() {
    this.submitted = true;
    if (this.verificationForm.valid) {
      if (this.status == 0) {
        let data = {
          id: this.id,
          otp: this.verificationForm.value.otp,
          isLogged: 0
        }
        this.loginApi.postVerification(data).subscribe((res) => {
          this.router.navigate(['auth/login'])
        }, error => {
          if (error.error.message) {
            let errmsg = error.error.message;
            this.verificationForm.controls['otp']
              .setErrors({ 'incorrect': true, 'errmsg': errmsg })
          }
        })
      } else {
        let data = {
          id: this.id,
          otp: this.verificationForm.value.otp,
          isLogged: 1
        }
        this.loginApi.postVerification(data).subscribe((res) => {
          this.router.navigate(['dashboard'])
        })
      }

    }
  }


  getVerification() {
    this.loginApi.getVerification(this.id).subscribe((res) => {
      // this.timer(5);
      this.toasterService.warning('New OTP has been send');
      this.over = null

    })
  }


  timer(minute) {

    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        this.over = "finished"
        clearInterval(timer);
      }
    }, 1000);
  }

}
