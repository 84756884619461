import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { ResetPasswordComponent} from "./auth/reset-password/reset-password.component"
import { ForgotPswdComponent } from './auth/forgot-pswd/forgot-pswd.component';
// import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from './shared/guard/admin.guard';
import { CreateAccountComponent } from './auth/create-account/create-account.component';
import { AccountVerificationComponent } from './auth/account-verification/account-verification.component';
import { SeccionExpiredComponent } from './auth/seccion-expired/seccion-expired.component';
import { WelcomePageComponent } from './auth/welcome-page/welcome-page.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { RecoveryEmailComponent } from './auth/recovery-email/recovery-email.component';
import { ResetPasswordNewComponent } from './auth/reset-password-new/reset-password-new.component';
import { ConfirmEmailComponent } from './auth/confirm-email/confirm-email.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';


const routes: Routes = [
  {
    path: '',
    //canActivate: [AdminGuard],
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  // {
  //   path: 'auth/login',
  //   component: LoginComponent
  // },
  {
    path: 'auth/login',
    component: SignInComponent
  },
  // { 
  //   path: 'reset-password/:token',
  //   component: ResetPasswordComponent
  // },
  // { 
  //   path: 'forgot-password',
  //   component: ForgotPswdComponent
  // },
  { 
    path: 'create-account',
    component: CreateAccountComponent
  },
  { 
    path: 'account-verification',
    component: AccountVerificationComponent
  },
  { 
    path: 'welcome-page',
    component: WelcomePageComponent
  },
  // { 
  //   path: 'sign-up',
  //   component: SignUpComponent
  // },
  { 
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  // { 
  //   path: 'sign-in',
  //   component: SignInComponent
  // },
  { 
    path: 'recovery-email',
    component: RecoveryEmailComponent
  },
  { 
    path: 'reset-password/:token',
    component: ResetPasswordNewComponent
  },
  { 
    path: 'confirm-email',
    component: ConfirmEmailComponent
  },
  { 
    path: 'set-password/:token',
    component: SetPasswordComponent
  },
  {
    path: 'dash',
    component: LoginComponent
  },
  {
    path: 'session-expired',
    component: SeccionExpiredComponent
  },
 
  
  {
    path:'settings',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/user/user.module').then(m => m.UserModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/project/project.module').then(m => m.ProjectModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/communication/communication.module').then(m => m.CommunicationModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    

    loadChildren:() => import('../app/templates/templates.module').then(m => m.TemplatesModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/tabs/tabs.module').then(m => m.TabsModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/ohns/ohns.module').then(m => m.OhnsModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/ohns-tabs/ohns-tabs.module').then(m => m.OhnsTabsModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/daily-reports-new/daily-reports-new.module').then(m => m.DailyReportsNewModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/daily-reports-new/daily-reports-new.module').then(m => m.DailyReportsNewModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/company/company.module').then(m => m.CompanyModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/vehicle-main/vehicle-main.module').then(m => m.VehicleMainModule)
  },
  {
    path:'',
    component:ContentComponent,
    canActivate: [AdminGuard],

    loadChildren:() => import('../app/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path:'',
    // component:ContentComponent,
    // canActivate: [AdminGuard],

    loadChildren:() => import('../app/company-user/company-user.module').then(m => m.CompanyUserModule)
  },
  {
    path:'',
    // component:ContentComponent,
    // canActivate: [AdminGuard],

    loadChildren:() => import('../app/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
 
  {
    path: '',
    component: FullComponent,
    canActivate: [AdminGuard],
    // children: full
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [[RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})],
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
