import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
const baseurl = environment.baseurl;

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {

  constructor(private http: HttpClient) {

  }

  updateprofile(data: any, id: number) {
    return this.http.post<any>(`${baseurl}update-profile`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  updateCompanyData(data: any) {
    return this.http.post<any>(`${baseurl}company-profile/`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getProfile(id: number) {
    return this.http.get<any>(`${baseurl}user-profile/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getCompanyData(id: number) {
    return this.http.get<any>(`${baseurl}company-profile/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  changePassword(data: any) {
    return this.http.post<any>(`${baseurl}change-password`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  changeProfileImage(data: any) {
    return this.http.post<any>(`${baseurl}profile-logo`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  changeCompanyImage(data: any) {
    return this.http.post<any>(`${baseurl}company-logo`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }


  /* Vehicle type apis */
  vehicleTypePost(data: any) {
    return this.http.post<any>(`${baseurl}vehicle-types`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  vehicleTypeUpdate(id: any, data: any) {
    return this.http.post<any>(`${baseurl}vehicle-types/${id}`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  vehicleTypeList(id: number) {
    return this.http.get<any>(`${baseurl}vehicle-types-list/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getVehicleTypeByid(id: number) {
    return this.http.get<any>(`${baseurl}vehicle-types/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  statusChange(id: number) {
    return this.http.get<any>(`${baseurl}vehicle-types-status/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  deleteVehicleType(id: number) {
    return this.http.delete<any>(`${baseurl}vehicle-types/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }
}