import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as feather from 'feather-icons';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import {TourComponent} from '../app/shared/components/tour/tour.component'
import { LoginApiService } from '../../../../shared/login-api.service';
import { SettingsApiService } from '../../../../shared/settings-api.service'

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  userId: any;
  tourId: any;
  userData: any;
  height="400vh";
  @ViewChild('openTour') openTour: any;
  videoURL = 'https://www.youtube.com/embed/b7_ix42ghCQ';
  showVideo:boolean=false
  userName: any;
  videoend:boolean=false
  constructor(public navServices: NavService, private router:Router,private settingsApiService:SettingsApiService,
    private loginService:LoginApiService,private modalService:NgbModal,
    public layout: LayoutService) {
     this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let userData = JSON.parse(localStorage.getItem('Zendit_user'))
        this.userId = (userData?.id) ?? 0;
       this.tourId = userData?.tour;
       this.userData=userData;
        const currentRoute = this.router.routerState.snapshot.url;
        const excludedRoutes = ['/auth/login', '/forgot-password', '/reset-password'];
        const isExcludedRoute = excludedRoutes.some((excludedRoute) => currentRoute.startsWith(excludedRoute));
        if (this.userData?.tour === 0 && !isExcludedRoute) {
          setTimeout(() => {
            this.getUserprofile()
            this.modalService.open(this.openTour, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
          }, 600);
        }
      }
    });
    }
    
  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  
  ngOnInit() {
  }

  skiptour(){
    this.loginService.skipTour(this.userId).subscribe(res =>{
      this.modalService.dismissAll();
      let userData = JSON.parse(localStorage.getItem('Zendit_user'))
       userData.tour=1
       localStorage.setItem('Zendit_user', JSON.stringify(userData));
    })
  }
  getUserprofile() {
    this.settingsApiService.getProfile(this.userId)
      .subscribe(res => {
       this.userName=(res.last_name)?(res.first_name+' '+res.last_name) : (res.first_name);
      })
  }
  playVideo(){
    this.showVideo = true;
  }
  onVideoEnd() {
    console.log('Video ended!');
    this.videoend=true;

  }

  // showTour(openTour){
  //   this.modalService.open(openTour, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
  // }

}
