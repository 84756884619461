import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { SafePipe } from '../app/safe.pipe'

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AutofocusDirective } from './autofocus.directive';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgotPswdComponent } from './auth/forgot-pswd/forgot-pswd.component';
import { CreateAccountComponent } from './auth/create-account/create-account.component';
import { AccountVerificationComponent } from './auth/account-verification/account-verification.component';
import { AuthInterceptor } from './shared/auth/auth.interceptor';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomDateParserFormatter } from './shared/helper/helper';
import { SeccionExpiredComponent } from './auth/seccion-expired/seccion-expired.component';
import { ProjectModule } from './project/project.module';
import { TabsModule } from './tabs/tabs.module';
import { GlobalErrorHandler } from './shared/helper/errorHandler';
import { WelcomePageComponent } from './auth/welcome-page/welcome-page.component';
// import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { RecoveryEmailComponent } from './auth/recovery-email/recovery-email.component';
import { ResetPasswordNewComponent } from './auth/reset-password-new/reset-password-new.component';
import { ConfirmEmailComponent } from './auth/confirm-email/confirm-email.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
// import {FloatingLabelComponent} from './shared/components/floating-label/floating-label.component';




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AutofocusDirective,
    ResetPasswordComponent,
    ForgotPswdComponent,
    CreateAccountComponent,
    AccountVerificationComponent,
    SeccionExpiredComponent,
    WelcomePageComponent,
    // SignUpComponent,
    ForgotPasswordComponent,
    SignInComponent,
    RecoveryEmailComponent,
    ResetPasswordNewComponent,
    ConfirmEmailComponent,
    SetPasswordComponent,
    // FloatingLabelComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    TooltipModule,
    DialogModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    Ng2SearchPipeModule,
 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    NgxIntlTelInputModule

  ],
  providers: [CustomDateParserFormatter, AuthService, AdminGuard, SecureInnerPagesGuard, CookieService, { provide: LocationStrategy, useClass: HashLocationStrategy }, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },{
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
