<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container">
      <div class="col-sm-8 offset-sm-2">
     
                  <div class="card mb-0" style="border-radius: 13px;">
                    <div class="insidecard">
                      <form class="theme-forms theme-form">
                        <div class="">
                          <div class="brand-wrapper text-center">
                            <img src="assets/images/icons/time-out.png" style="width:18% !important" alt="logo" class="logo">
                          </div>
                          <h6 class="login-card-description text-center">Your session has expired</h6>
                          <p class="text-center f-11">Please login to your account to continue.
                          </p>
                          
                       
                                     
                          <div class="text-center mt-2">
                            <button class="btn btn-sm btnnew"  routerLink="/auth/login"  type="submit" >Login <i
                              class="fa-fw fa fa-arrow-right" aria-hidden="true"></i></button>
                          </div>
                          <!-- <a routerLink="/auth/login" class="forgot-password-link ">Back to login</a> -->
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
         
  </main>