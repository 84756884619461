import { Injectable } from '@angular/core';
import{HttpClient}from '@angular/common/http'
import { map } from 'rxjs/operators'
import {environment} from '../../environments/environment';
const baseurl=environment.baseurl;
@Injectable({
  providedIn: 'root'
})
export class RoleApiService {

  constructor(private http:HttpClient) { }

  getRoleSettings(){
    return this.http.get<any>(`${baseurl}role-settings/create`)
    .pipe(map((res:any)=>{
      return res;
    }))
  } 
  postRoleSettings(data:any){
    return this.http.post<any>(`${baseurl}role-settings`,data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  listRoles(id:any){
    return this.http.get<any>(`${baseurl}list-role-settings/`+id)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  roleStatusChange(id:any){
    return this.http.get<any>(`${baseurl}role-status/`+id)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  getRoleById(id:any){
    return this.http.get<any>(`${baseurl}role-settings/`+id)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
}
