<main class="d-flex align-items-center min-vh-100 py-3 py-md-0 bg-image">
  <div class="container">
    <div class="col-sm-8 offset-sm-2">
      <div class="card login-card">
        <div class="card-body">
          <form class="theme-forms theme-form" [formGroup]="createAccountForm" (ngSubmit)="createAccountSubmit()">
            <div class="form-group">
              <div class="brand-wrapper text-center">
                <img src="assets/images/logo/logochange.png" alt="logo" class="logo">
              </div>
              <h6 class="login-card-description text-center">Create an account</h6>
              <div class="row">
                <div class="col-sm-6">
                  <label class="mb-0">First name</label>
                  <input type="text" class="form-control" formControlName="first_name" placeholder="First name">
                  <div
                    *ngIf="(submitted || createAccountForm.controls.first_name.touched) && createAccountForm.controls.first_name.errors"
                    class="error-notify">
                    <div *ngIf="createAccountForm.controls.first_name.errors.required" class="text-danger mt-1">This
                      field is required</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="mb-0">Last name</label>
                  <input type="text" class="form-control" formControlName="last_name" placeholder="Last name">
                  <div
                    *ngIf="(submitted || createAccountForm.controls.last_name.touched) && createAccountForm.controls.last_name.errors"
                    class="error-notify">
                    <div *ngIf="createAccountForm.controls.last_name.errors.required" class="text-danger mt-1">This
                      field is required</div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-6">
                  <label class="mb-0">Email</label>
                  <input type="email" class="form-control" formControlName="email" placeholder="Email">
                  <div
                    *ngIf="(submitted || createAccountForm.controls.email.touched) && createAccountForm.controls.email.errors"
                    class="error-notify">
                    <div *ngIf="createAccountForm.controls.email.errors.required" class="text-danger mt-1">This
                      field is required</div>
                    <div *ngIf="createAccountForm.controls.email.errors.email" class="text-danger mt-1">Invalid email
                      format</div>
                      <div *ngIf="createAccountForm.controls.email.errors.incorrect" class="text-danger mt-1">{{createAccountForm.controls.email.errors.errmsg}}</div>

                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="mb-0">Mobile number</label>
                  <input type="text" class="form-control" formControlName="phone_number" placeholder="Mobile number">
                  <div
                    *ngIf="(submitted || createAccountForm.controls.phone_number.touched) && createAccountForm.controls.phone_number.errors"
                    class="error-notify">
                    <div *ngIf="createAccountForm.controls.phone_number.errors.required" class="text-danger mt-1">This
                      field is required</div>
                    <div *ngIf="createAccountForm.controls.phone_number.errors.pattern" class="text-danger mt-1">Please 
                      type numbers only
                    </div>

                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <label class="mb-0">Address</label>
                  <input type="text" class="form-control" formControlName="address" placeholder="Address">
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <label class="mb-0">Password</label>
                  <input type="password" class="form-control" formControlName="password" placeholder="Password">
                  <div
                    *ngIf="(submitted || createAccountForm.controls.password.touched) && createAccountForm.controls.password.errors"
                    class="error-notify">
                    <div *ngIf="createAccountForm.controls.password.errors.required" class="text-danger mt-1">This
                      field is required</div>
                   

                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm-12">
                  <label class="mb-0">Confirm password</label>
                  <div class="input-group">
                    <input type="password" id="password" formControlName="password_confirmation" #password1
                      class="form-control" placeholder="Confirm password">
                    <div class="input-group-append" (click)="changeType(password1, 1)">
                      <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                          style="font-size: 11px;"></i></span>
                    </div>
                  </div>
                  <div
                  *ngIf="(submitted || createAccountForm.controls.password_confirmation.touched) && createAccountForm.controls.password_confirmation.errors"
                  class="error-notify">
                  <div *ngIf="createAccountForm.controls.password_confirmation.errors.required" class="text-danger mt-1">This
                    field is required</div>
                    <div *ngIf="createAccountForm.controls.password_confirmation.errors.incorrect" class="text-danger mt-1">{{createAccountForm.controls.password_confirmation.errors.errmsg}}</div>
                 

                </div>
                </div>
              </div>
              <div class="row check mt-3">
                <div class="col-sm-12">
                  <label class="control-label">
                    <input class="checkbox_animated" formControlName="isTerms" type="checkbox">By continuing
                    you agree to our Terms of Service and Privacy Policy.
                  </label>
                  <div
                  *ngIf="(submitted || createAccountForm.controls.isTerms.touched) && createAccountForm.controls.isTerms.errors"
                  class="error-notify">
                  <div *ngIf="createAccountForm.controls.isTerms.errors.required" class="text-danger mt-1">You must agree with the 
                    terms of service and privacy policy</div>
                 

                </div>
                  
                </div>
              </div>
              <div class="text-center mt-2">
                <button class="btn btn-sm login-btn mb-3" type="submit">Register
                </button>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="text-center">
                    <a routerLink="/auth/login" class="toLogin">Already have an account? Login</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</main>