<main class="d-flex align-items-center min-vh-100 py-3 py-md-0 bg_color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <div class="welcome_header" style="background-color: #ffffff00;">
                    <!-- <div class="header">
                        <img src="assets/images/logo/zendit-logo.png" alt="login" class="logo_width">
                    </div> -->
                </div>
                <div class="col-sm-5 offset-sm-4">
                    <h1 class="welcome_heading">Set your password</h1>
                    <form action="" [formGroup]="passwordForm">
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <label class="control-label">New password</label> -->
                                <!-- <div class="input-group"> -->
                                    <!-- <div class="md-form md-outline form-lg" [ngClass]="{'active': isInputFocused || passwordForm.get('password').value}">
                                        <input class="form-control form-control-lg" #password1 id="password1" formControlName="password"  (focus)="onInputFocus('1')" (blur)="onInputBlur('1')">
                                        <label>New password  * </label>
                                      </div> -->
                                    <app-floating-label style="width:308px" label="Password *"  [control]="passwordForm.get('password')">
                                    </app-floating-label>
                                    <!-- <input type="password" #password1 id="password" class="form-control password"
                                        placeholder="Password"> -->
                                    <!-- <div class="input-group-append" (click)="changeType(password1, 1)">
                                        <span class="input-group-text pwd"><i
                                                [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                                style="font-size: 11px;margin-top: 3px;"></i></span>
                                    </div> -->
                                <!-- </div> -->
                                <div *ngIf="(submitted || passwordForm.controls.password.touched)&& passwordForm.controls.password.errors"
                                    class="error-notify">
                                    <div *ngIf="passwordForm.controls.password.errors.required"
                                        class="text-danger mt-1">
                                        This field is required.
                                    </div>
                                    <!-- <div *ngIf="passwordForm.controls.password.errors.incorrect" class="text-danger mt-1">
                           {{passwordForm.controls.password.errors.errmsg}}
                          </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <!-- <label class="control-label">Retry new password</label> -->
                                <!-- <div class="input-group"> -->
                                    <!-- <div class="md-form md-outline form-lg" [ngClass]="{'active': isInputFocused2 || passwordForm.get('password_confirmation').value}">
                                        <input class="form-control form-control-lg" #password2 id="password2" formControlName="password_confirmation"  (focus)="onInputFocus('2')" (blur)="onInputBlur('2')">
                                        <label>Retry new password  * </label>
                                      </div> -->
                                    <app-floating-label style="width:308px " label="Confirm password *"   [control]="passwordForm.get('password_confirmation')">
                                    </app-floating-label>
                                    <!-- <input type="password" #password1 id="password" class="form-control password"
                                        placeholder="Password"> -->
                                    <!-- <div class="input-group-append" (click)="changeType(password2, 1)">
                                        <span class="input-group-text pwd"><i
                                                [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                                style="font-size: 11px;margin-top: 3px;"></i></span>
                                    </div> -->
                                <!-- </div> -->
                                <div *ngIf="(submitted || passwordForm.controls.password_confirmation.touched)&& passwordForm.controls.password_confirmation.errors"
                                    class="error-notify">
                                    <div *ngIf="passwordForm.controls.password_confirmation.errors.required"
                                        class="text-danger mt-1">
                                        This field is required.
                                    </div>
                                    <div *ngIf="passwordForm.controls.password_confirmation.errors.incorrect"
                                        class="text-danger mt-1">
                                        {{passwordForm.controls.password_confirmation.errors.errmsg}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <button class="btn btn-sm btn-block btnnew mb-3" type="submit" (click)="resetSubmit()"><span
                                    style="padding-right: 10px;text-transform: uppercase;">Sign up</span></button>
                        </div>
                        <div class="text-center mt-3">
                            <button class="btn btn-sm btn-block btn-black mb-3"  routerLink="/auth/login"><span
                                    style="padding-right: 10px;text-transform: uppercase;">BACK to login</span>
                            </button>
                        </div>
                    </form>
                    <div class="welcome_footer">
                        <div class="footer">
                            <p class="">Terms and Conditions <span style="color: #263032;font-size: 8px;"><i
                                        aria-hidden="true" class="fa fa-circle mr-2 ml-2"></i></span> Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
            </div>
        </div>
    </div>
</main>