<!-- Page Header Start-->
<div class="page-main-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="main-header-right row m-0">
    <form class="form-inline search-full" [class.open]="search">
      <div class="form-group w-100">
        <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
          (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search..">
        <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
        <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">
          <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
            <div class="ProfileCard-avatar">
              <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
            </div>
            <div class="ProfileCard-details">
              <div class="ProfileCard-realName">
                <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Opps!! There are no result found.
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="main-header-left">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div> -->
    <div class="nav-right col-sm-12">
      <div class="row">
        <!-- <div class="col-sm-2">
          <div class="image">
            <img src="assets/images/logo/zendit-logo.png" alt=""
              style="width: 100px;margin-left: 20px;margin-top: 6px;">
          </div>
        </div> -->
        <!-- <div class="col-sm-9 col-xs-9  menu-center column-width">
          <div class="d-flex align-items-center">
            <div class="image m-r-60">
              <img src="assets/images/logo/zendit-logo.png" alt=""
               >
            </div>
            <div (click)="actionToggle()" class="action" *ngIf="!showIcon">
              <i class="fa fa-bars f-20" *ngIf="!showIcon" aria-hidden="true" ></i>
            </div>
            <div (click)="actionToggle()" *ngIf="showIcon"  class="action" >
              <i class="fa fa-times f-20" *ngIf="showIcon" aria-hidden="true"></i>
            </div>
            <ul *ngIf="showIcon" class="menus-new-list" >
           
              <li>
                <app-sidebar></app-sidebar>
              </li>
          
            </ul> 
            <app-sidebar class="Menu-items"></app-sidebar>
          </div>
         
        </div> -->
        <div class="col-sm-8  menu-center column-width">
          <div class="d-flex align-items-center">
            <div class="image m-r-60">
              <!-- <img src="assets/images/logo/zendit-logo.jpg" alt="" -->
            </div>
            <div class="sidebar-opener action" (click)="sidebarShow = !sidebarShow">
              <i class="fa fa-bars f-20"  aria-hidden="true" ></i>
            </div>
            <!-- <div (click)="actionToggle()" *ngIf="showIcon"  class="action" >
              <i class="fa fa-times f-20" *ngIf="showIcon" aria-hidden="true"></i>
            </div> -->
            <div class="sidebar-slider action" [class.sidebar-slide-in]="sidebarShow">
              <div class="sidebar-close" (click)="toggleSideBar()">
                <div class="image1 m-r-60">
                  <!-- <img src="assets/images/logo/zendit-logo.png" alt=""> -->
                </div>
                <i class="fa fa-times f-20 text-dark"   aria-hidden="true" ></i>
              </div>
              <div class="sidebar-content">
                <!-- <app-sidebar></app-sidebar> -->
              </div>
            </div> 
            <!-- <app-sidebar class="Menu-items"></app-sidebar> -->
          </div>
         
        </div>
        <!-- <div class="col-sm-3"> 
          <ul class="nav-menus">
            <li class="profile-nav onhover-dropdown p-0">
              <div class=" profile-media media" style="align-items:center">
                <form id="custom-search-form" class="form-search form-horizontal ">
                  <input type="text" [(ngModel)]="searchQuery" class="search-query " [ngModelOptions]="{standalone: true}" (input)="onInput()">

                  <button type="button" class="btn"><i class="icon-search"></i></button>
                </form>
              </div>
              <div *ngIf="filteredItems?.length!=0">
                <ul class="border" >
                  <div *ngFor="let suggestion of filteredItems">
                    <li  class="border-dropdown" >
                      <a (click)="navigateToProject(suggestion)">{{ suggestion.project_name }} </a>
                    </li>
                    <hr>
                  </div>
                </ul>
                
              </div>
              
            </li>
          </ul>
        </div> -->
        <div class="col-sm-4 column-width">
          <div class="pull-right right-men">
            <ul class="nav-menus">
              <li class="profile-nav onhover-dropdown p-0">
                <div class=" profile-media media" style="align-items:center">
                  <img class="b-r-10 mr-2" [src]="userImage? userImage: defaultUserProfileUrl" alt="" style="width: 40px;height:40px">
                  <div class="media-body"><span class="mr-2">{{username}} {{lastName}}</span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div">
                  <!-- <li routerLink="/settings/profile">
                    <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span>
                  </li> -->
                  <li (click)="logoutAlert()">
                    <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                  </li>
                  <!-- <ng-template #logout>
              <div class="modal-body text-center">
                <form class="form theme-form">
                  <div class="icon">
                    <i class="fa fa-exclamation-circle fa-4x text-warning  " aria-hidden="true"></i>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 text-center ">
                      <div class="mt-2">
                        <h4 class=" mb-2">Are you leaving? </h4>
                        <p>Are you sure want to logout?.All your <br> unsaved data will be lost.</p>
                      </div>

                    </div>
                  </div>

                </form>
              </div>
              <div class="row mb-3">
                <div class="col-sm-6">
                  <button type="button" class="btn btncolor pull-right btn-sm " routerLink="/auth/login">Yes</button>
                </div>
                <div class="col-sm-6 ">
                  <button type="button" class="btn btn-danger btn-sm ">Cancel</button>
                </div>
              </div>
            </ng-template> -->
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Header Ends -->