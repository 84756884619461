import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { TokenService } from "../auth/token.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Router } from "@angular/router";

 @Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService,private router:Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const accessToken = this.tokenService.getToken();
        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + accessToken,
            }

        }); 

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error.error === "Token has expired") {
                    // Session expired, navigate to login
                    this.navigateToSession();
                }
                return throwError(error);
            })
        );
    }

    navigateToSession() {
        localStorage.setItem('loggedIn', 'false');
        localStorage.removeItem('Zendit_user');
        localStorage.removeItem('logindetail');
        this.router.navigate(['/session-expired']);
    }
}