import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoginApiService } from 'src/app/shared/login-api.service';
import { TokenService } from 'src/app/shared/auth/token.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  password: string = '';
  c_password: string = '';
  o_password: string = '';
  toggle1: boolean = false;
  toggle2: boolean = false;
  toggle3: boolean = false;
  toggle4: boolean = false;


  passwordForm: FormGroup;
  id: number;
  submitted = false;

  constructor(public authService: AuthService,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,
    private loginService: LoginApiService,
    private token: TokenService) {
    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
      token: [null],
    });
    this.route.params.forEach((params: Params) => {
      this.token = params['token'];
    })
    this.passwordForm.controls.token.setValue(this.token);

  }
  ngOnInit() {
  }

  changeType(input_field_password: any, num: any) {
    if (input_field_password.type == "password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if (num == 1) {
      this.toggle1 = !this.toggle1;
    }
    else if (num == 2) {
      this.toggle2 = !this.toggle2;
    }
    else {
      this.toggle3 = !this.toggle3;
    }

  }

  resetSubmit() {
    this.submitted = true;
    if (this.passwordForm.valid) {
      let data = {
        password: this.passwordForm.value.password,
        password_confirmation: this.passwordForm.value.password_confirmation,
        token: this.passwordForm.value.token
      }
      this.loginService.postReset(data)
        .subscribe((res: any) => {

          // Swal.fire({                                             
          //   text : " password changed Successfully!",                                          
          //   icon: "success",                                             
          // })    
          let message = res?.message;
          message = (message) ? message : 'Password changed successfully!'

          let status = res?.status
          if (status !== "failure") {
            this.toastr.success(message, ' ', {
              timeOut: 3000,
            });
            this.router.navigate(['/auth/login']);

          } else {
            this.toastr.warning(message, ' ', {
              timeOut: 3000,
            });
          }
        },
          (error: any) => {
            ////console.log(error);
            let finalError = error.error.errors;
            if (finalError?.password) {
              let errmsg = (finalError?.password) ?? 'Passwords mismatch!';
              this.passwordForm.controls['password_confirmation']
                .setErrors({ 'incorrect': true, 'errmsg': errmsg })
            }
          }
        );

    }
  }


}
