<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-sm-6 offset-sm-3">
      <div class="card login-card" style="box-shadow: none;">
        <div class="card-body loginbody">
          <div class="row ">
            <!-- <div class="col-sm-6">
              <div class="image-fluid ">
                <img src="assets/images/Backgrounds/loginimg.png" alt="login" class="login-card-img">
              </div>
            </div> -->
            <div class="col-sm-12">
              <div class="p3_rem">
                <div class="card mb-0" style="border-radius: 13px;">
                  <div class="insidecard">
                    <form class="theme-forms theme-form" action="#!" [formGroup]="loginForm" *ngIf="!newUser">
                      <div class="">
                        <div class="brand-wrapper text-center">
                          <img src="assets/images/logo/zendit-logo.png" alt="logo" class="logo">
                        </div>
                        <h6 class="login-card-description text-center">Login to your account</h6>
                        <div class="form-group row">
                          <div class="col-sm-12">                         
                              <div class=" error-notify" *ngIf="(submitted) && (loginForm.controls.email.errors || loginForm.controls.password.errors)" >
                                <div  >
                                  <div class="login-alert" *ngIf="loginForm.controls?.email?.errors?.email || loginForm.controls?.email?.errors?.incorrect || loginForm.controls?.password?.errors?.incorrect">
                                    <p class="text-white" ><i class="fa fa-exclamation-circle mr-2 f-13" aria-hidden="true"></i>Login credentials are incorrect.</p>
                                  </div>
                                </div>
                                <!-- <div  *ngIf="loginForm.controls.password.errors">
                                  <div class="login-alert" *ngIf="loginForm.controls.password.errors.incorrect">
                                    <p class="text-white" ><i class="fa fa-exclamation-circle mr-2 f-13" aria-hidden="true"></i>Login credentials are incorrect.</p>
                                  </div>
                                </div> -->
                              </div>                 
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label for="email" class="control-label">Email address</label><span class="asterisk">*</span>
                            <input type="email" name="email" id="email" class="form-control" formControlName="email"
                              placeholder="Email address">
                            <div
                              *ngIf="(submitted || loginForm.controls.email.touched)&& loginForm.controls.email.errors"
                              class="error-notify">
                              <div *ngIf="loginForm.controls.email.errors.required" class="text-danger mt-1">
                                This field is required.
                              </div>
                              <!-- <div *ngIf="loginForm.controls.email.errors.email" class="text-danger mt-1">
                                Invalid email format.
                              </div>
                              <div *ngIf="loginForm.controls.email.errors.incorrect" class="text-danger mt-1">
                                {{loginForm.controls.email.errors.errmsg}}
                              </div> -->
                              
                            </div>
                          </div>
                        </div>
                        <div class="row form-group mb-4">
                          <div class="col-sm-12">
                            <label for="password" class="control-label">Password</label><span class="asterisk">*</span>
                            <div class="input-group">
                              <input type="password" id="password" #password1 class="form-control"
                                placeholder="Password" formControlName="password">
                              <div class="input-group-append" (click)="changeType(password1, 1)">
                                <span class="input-group-text"><i [ngClass]="toggle1 ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    style="font-size: 11px;"></i></span>
                              </div>
                            </div>
                            <div
                              *ngIf="(submitted || loginForm.controls.password.touched)&& loginForm.controls.password.errors"
                              class="error-notify">
                              <div *ngIf="loginForm.controls.password.errors.required" class="text-danger mt-1">
                                This field is required.
                              </div>
                              <!-- <div *ngIf="loginForm.controls.password.errors.incorrect" class="text-danger mt-1">
                               {{loginForm.controls.password.errors.errmsg}}
                              </div> -->
                            </div>
                          </div>
                        </div>
                        <div class="form-group row check">
                          <div class="col-sm-7">
                            <label class="control-label" style="font-size:11px !important" for="chk-ani" >
                        <input class="checkbox_animated" id="" type="checkbox"  formControlName="rememberme">Remember me
                      </label>
                          </div>
                          <div class="col-sm-5">
                            <a routerLink="/forgot-password" class="forgot-password-link">Forgot password?</a>
                          </div>
                        </div>
                        <div class="text-center">
                          <button class="btn btn-sm btn-block btncolor mb-3" (click)="login()" type="submit"><span>{{
                              authService.showLoader ? '' : 'Login' }}</span>
                          </button>
                        </div>
                        <!-- <p class="login-card-footer-text">Don't have an account? <a href="#!" class="text-reset">Register here</a></p> -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>