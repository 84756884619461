import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { TokenService } from 'src/app/shared/auth/token.service';
import { LoginApiService } from '../../shared/login-api.service'
import { RoleApiService } from '../../shared/role-api.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NavService } from 'src/app/shared/services/nav.service';
// import { FloatingLabelComponent } from 'src/app/shared/components/floating-label/floating-label.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  
  
})
export class SignInComponent implements OnInit {
  visible:boolean = false
  notvisible:boolean = true
  toggle1: boolean = false;
  toggle2: boolean = false;
  isInputFocused: boolean = false;
  inputValue: string = '';
  email:string='';
  public newUser = false;
  public signup: boolean;
  public user: firebase.User;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;
  submitted = false;
  adminData: any;
  statusCheck: any;
  status: string;
  public errorMessage: any;
  items: any;

  constructor(public authService: AuthService, @Inject(DOCUMENT) private document: Document,
  private fb: FormBuilder, public loginService: LoginApiService,
  private token: TokenService, private toastr: ToastrService,
  private route: ActivatedRoute,
  public navServices: NavService,
  private roleApiService: RoleApiService,
  public router: Router) {
    this.loginForm = fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      rememberme: [null]
    });
   }

  ngOnInit() {
    this.navServices.items.subscribe(menuItems => this.items = menuItems);
    console.log(this.items)

    let loginData = JSON.parse(localStorage.getItem('logindetail'));
    if (loginData) {
      this.loginForm.controls.email.setValue(loginData.email)
      this.loginForm.controls.password.setValue(loginData.password)
      this.loginForm.controls.rememberme.setValue(true)
    } else {
      this.loginForm.controls.email.setValue(null)
      this.loginForm.controls.password.setValue(null)
      this.loginForm.controls.rememberme.setValue(false)
    }
    const loggedIn = localStorage.getItem('loggedIn');
    if (loggedIn === 'true') {
      const currentUrl = this.router.url;
      window.history.pushState({}, '', currentUrl);

      window.history.pushState(null, null, currentUrl);
      // window.onpopstate = function () {
      //   ////console.log('log event triggered');
      //   window.history.go(1);
      // };

      const currentUrls = this.router.url;
      window.history.replaceState({}, '', currentUrls);
      let userData = JSON.parse(localStorage.getItem('Zendit_user'))
      console.log(userData)
      if (userData?.role == 1) {
        this.router.navigate(['company-list']);
      } else {
        this.router.navigate(['project/project-progressing']);
      }
    }
  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  onclick() {
    this.visible = !this.visible
    this.notvisible = !this.notvisible
  }

  changeType(input_field_password, num){
    if(input_field_password.type == "password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";
    if(num == 1)
      this.toggle1 = !this.toggle1;
    else
      this.toggle2 = !this.toggle2;
  }

  // Simple Login
  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      // this.rememberMe();
      let data = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      }
      this.loginService.postLogin(data)
        .subscribe((res: any) => {
          let User = res.user;
          let UserString = JSON.stringify(User);
          localStorage.setItem('Zendit_user', UserString);
          let Company = res.company;
          let CompanyString = JSON.stringify(Company);
          localStorage.setItem('Zendit_company', CompanyString);
          if (this.loginForm.value.rememberme == true) {
            localStorage.setItem('loggedIn', 'true');
          } else {
            localStorage.setItem('loggedIn', 'false');
          }
          this.responseHandler(res);
          // setTimeout(() => {
          this.navigateRoute(res)

          // },100)
        },
          error => {
            console.log(error)
            if (error.error.type == 1 && error.error.error =="Login credentials are incorrect." ) {
              let errmsg = error.error.error;
              this.loginForm.controls['email']
                .setErrors({ 'incorrect': true, 'errmsg': errmsg })
            } else if (error.error.type == 2 && error.error.error =="Login credentials are incorrect.") {
              let errmsg = (error?.error?.error);
              this.loginForm.controls['password']
                .setErrors({ 'incorrect': true, 'errmsg': errmsg })
            }else if (error.error.error =="You are not authorised to perform this action. Please contact the administrator.") {
              console.log('here')
              this.toastr.warning(error.error.error,'')
            }
          })
    }
  }

  responseHandler(data: any) {
    this.token.handleData(data.access_token);
  }


  navigateRoute(result) {
    this.roleApiService.getRoleById(result?.user?.role).subscribe((res) => {
      if (result?.user?.role == 1 ) {
        this.router.navigate(['company-list']);
      } else {
        if (res?.role?.role == 'Company') {
          this.router.navigate(['project/project-progressing']);
        } else {
          if (res?.settings?.length > 0) {
            const menuIdOrder = [4, 1, 5, 2, 3, 6, 7];
            res.settings.sort(function (a: any, b: any) {
              return menuIdOrder.indexOf(a.menu_id) - menuIdOrder.indexOf(b.menu_id);
            });
            console.log(res?.settings, 'order')
            const menu = res?.settings[0]
            if (menu.menu_id == 1) {
              this.router.navigate(['dashboard']);
            } else if (menu.menu_id == 2) {
              this.router.navigate(['user']);
            } else if (menu.menu_id == 3) {
              this.router.navigate(['list-template']);
            } else if (menu.menu_id == 4) {
              this.router.navigate(['project/project-progressing']);
            } else if (menu.menu_id == 5) {
              this.router.navigate(['view-daily-reports']);
            } else if (menu.menu_id == 6) {
              this.router.navigate(['roles-list']);
            } if (menu.menu_id == 7) {
              this.router.navigate(['settings']);
            }
          } else {
            this.toastr.warning('Your dashboard is not set up. Please contact the administrator.');
          }
        }
      }
    })
  }

}
