import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, ReplaySubject } from 'rxjs';
import {RoleApiService} from '../../shared/role-api.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRoleService {
  result: any;
  role: any;
  settings: any;
  private resultSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public result$ = this.resultSubject.asObservable();
  constructor(private roleApiService:RoleApiService) {
    
   }
   CheckRole(id){
		this.roleApiService.getRoleById(id).subscribe((res)=>{
        this.resultSubject.next(res); // Emit the result

		})
	  }
	nullifyMenuItems(): void {
		let UserString = localStorage.getItem('Zendit_user');
		let adminJSON = JSON.parse(UserString);
		const role = adminJSON?.role;
		this.CheckRole(role);
	  }

}
