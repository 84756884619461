<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="welcome_header" style="background-color: #ffffff00;">
                    <!-- <div class="header">
                        <img src="assets/images/logo/zendit-logo.png" alt="login" class="logo_width">
                    </div> -->
                </div>
                <div class="col-sm-3 offset-sm-4">
                    <div class="p1_rem">
                        <h1 class="welcome_heading">Recovery email sent!</h1>
                        <p class="welcome_para">Please check your email for next steps to reset your password.</p>
                        <form action="">
                            <div class="text-center mt-3">
                                <button class="btn btn-sm btn-block btnnew mb-3"  type="submit"><span style="padding-right: 10px;text-transform: uppercase;">Contact support</span></button>
                            </div>
                            <div class="text-center mt-80">
                                <button class="btn btn-sm btn-block btn-black mb-3"  type="submit" routerLink="/auth/login" ><span style="padding-right: 10px;text-transform: uppercase;">BACK to login</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="welcome_footer">
                        <div class="footer">
                            <p class="">Terms and Conditions <span style="color: #263032;font-size: 8px;"><i aria-hidden="true" class="fa fa-circle mr-2 ml-2"></i></span> Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</main>


