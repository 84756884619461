import { Component, OnInit, Inject, Renderer2  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NavService, Menu } from '../../services/nav.service';
import { AuthService } from '../../services/firebase/auth.service';
import { LayoutService } from '../../services/layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {LoginApiService} from '../../../shared/login-api.service'
import { SettingsApiService } from '../../settings-api.service';
import { ProjectApiService } from '../../project-api.service';

const Hashids = require('hashids/cjs');
const hashids = new Hashids('', 10);
declare var require
const Swal = require('sweetalert2')
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: []
})
export class HeaderComponent implements OnInit {
  searchQuery = null;
  suggestions = [];
  public menuItems: Menu[];
  public elem: any;
  public items: Menu[];
  public megaItems: Menu[];
  public username:any;
  public lastName:any;
  public language: boolean = false;
  public search: boolean = false;
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string;
  public userImage:any;
  data:any;
  public defaultUserProfileUrl: string = 'assets/images/user/default.jpg';
  public dark: boolean = this.layout.config.color.mix_background_layout == 'dark-only' ? true : false;
  userId:any;
  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [{
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es'
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    },
    {
      language: 'Português',
      code: 'pt',
      type: 'BR',
      icon: 'pt'
  }]
  projectListData: any;
  projectData: any;
  companyId: any;
  filteredItems:any[]=[];
  showIcon:boolean = false;
  public sidebarShow: boolean = false;

  constructor(private translate: TranslateService, public layout: LayoutService,private loginAPi:LoginApiService,
    public navServices: NavService, @Inject(DOCUMENT) private document: any,private modalService: NgbModal,
    public authService: AuthService,private router: Router, private settingsService:SettingsApiService, private projectApiService: ProjectApiService,
    private renderer: Renderer2 ) {
  }

  ngOnInit() {
    
    let userData=  JSON.parse(localStorage.getItem('Zendit_user'))
    this.userId =(userData?.id)??0;
    let companyData = JSON.parse(localStorage.getItem('Zendit_company'));
    this.companyId = (companyData?.id) ?? 0;
   // this.projectList(this.companyId);
      // ////console.log(this.data)
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => this.items = menuItems);
    this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    this. getUserData();
    const script = this.renderer.createElement('script');
    script.src = '//cdn.freshmarketer.com/8705247/3380325.js';
    this.renderer.appendChild(document.head, script);
    const script1 = this.renderer.createElement('script');
  script.type = 'text/javascript';
  script.text = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hii1etcgel");
  `;
  this.renderer.appendChild(document.head, script1);
  }

  // onInput() {
  //   if(this.searchQuery && this.searchQuery.trim().length > 0){
  //     this.filteredItems = this.projectData.filter((history) => {
  //       return JSON.stringify(history).toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1 || !this.searchQuery;
  //     });
  //     const suggestion = { project_name: `Search for "${this.searchQuery}" in`, page: 'search' };
  //     this.filteredItems.unshift(suggestion);
  //   }else {
  //     this.filteredItems=[]
  //   }
  //   }
  // projectList(id: any) {
  //   this.projectListData = this.projectApiService.listProject(id).subscribe((res) => {
  //     this.projectData = res; 
  //     var users = [];
  //     this.projectData?.forEach((element,i) => {
  //       const groupedTasksArray: any = element?.project_tower ? this.groupBy(element?.project_tower, item => item.id) : [];
  //       element.tower_length = groupedTasksArray?.size;
  //     });
  //   });
  // }
  
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  navigateToProject(project) {
    // navigate to the project page using the project ID
    let projectid = hashids.encode(project?.id);

    this.router.navigate(['/view-project',projectid]);
    this.filteredItems=[];
    this.searchQuery='';
    this.reloadComponent()
  } 
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
       // this.router.navigate([currentUrl]);
  }
   getUserData(){

      this.settingsService.getProfile(this.userId).subscribe(res =>{
        this.userImage =(res.image_path)? (environment.image_url+res.image_path):null;
        this.username = (res.first_name)??''+' '+(res.last_name)??'';
        this.lastName = (res.last_name)??'';
      })
  }
  changeLanguage(lang) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  megaMenuToggle() {
    this.navServices.megaMenu = !this.navServices.megaMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }

  languageToggle() {
    this.language = !this.language;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.color.mix_background_layout = this.dark ? 'dark-only' : 'light';
  }

  searchToggle() {
    this.search = !this.search;
    this.language = false;
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (!menuItems?.title) return false
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName('body')[0].classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName('body')[0].classList.remove('offcanvas');
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }
  backToLoginAlert(logout){
    this.modalService.open(logout, {backdropClass:"light-blue-backdrop", windowClass: "my-class", size: "md" });
    }

    private popstateListener: () => void;

    logoutAlert() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        
        text: 'Are you leaving?',
        icon: 'warning',
        // text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          // this.loginAPi.postLogout().subscribe((res)=>{
          //   ////console.log(res)
          // })
          this.router.navigate(['auth/login']);

          localStorage.setItem('loggedIn', 'false');
          localStorage.removeItem('Zendit_user');
          // this.navServices.nullifyMenuItems();
          //console.log(this.navServices.items)
        //  localStorage.removeItem('Roleresult');

        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          
        }
      })
    }

  

    toggleSideBar(){
      this.sidebarShow = !this.sidebarShow
    }
}
