<div class="container-fluid justify-content-center main">
  <!-- Reset Password page start-->
  <div class="authentication-main mt-0">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="auth-innerright auth-minibox">
          <div class="authentication-box auth-minibox1">
            <div class="card login-card">
              <div class="card-body">
                <form class="theme-forms theme-form" [formGroup]="verificationForm">
                
                  <div class="form-group">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="  " style=" align-items: center; display: flex; justify-content: center;">
                      <img src="assets/images/logo/logochange.png" class="mb-3 " style="width: 80% ; " alt="">
                    </div>
                    <h6 class=" text-center">Mobile Two-Factor Authentication</h6>
                    <p class="text-center f-11">Your verification code has been sent to {{phone}}<br>Please enter it below to log into web portal
                    </p>
                    <!-- <p class="text-center f-11">Please enter it below to log into web portal </p> -->
                    <div class="form-group">
                      <div class="row p-4">
                        <div class="col-sm-12">
                          <label>Enter two factor code</label>
                          <input type="text" class="form-control" formControlName="otp" placeholder="Enter two factor code">
                          <div *ngIf="submitted && verificationForm.controls.otp.errors" class="error-notify">
                            <div *ngIf="verificationForm.controls.otp.errors.required" class="text-danger mt-1">
                               This field is required
                            </div>
                            <div *ngIf="verificationForm.controls.otp.errors.incorrect" class="text-danger mt-1">
                              {{verificationForm.controls.otp.errors.errmsg}}
                           </div>
                            
                        </div>
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col-sm-12">
                        <div class="text-center">
                        </div>
                        <div class="text-center" >
                          <p style="margin-bottom:0px" class="create-account">Dont receive the OTP? </p>
                          <h6 class="Resent"  (click)="getVerification()">Resend code</h6>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-md-12 text-center">
                        <button class="btn btncolor btn-sm mb-3" type="submit" style="color: white;" (click)="onSubmit()">Verify & Login</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Reset Password page end-->
</div>