
<main class="d-flex align-items-center min-vh-100 py-3 py-md-0">
  <div class="container">
    <div class="col-sm-10 offset-sm-1">
      <div class="card login-card">
        <div class="card-body loginbody">
          <div class="row ">
            <div class="col-sm-6">
              <div class="image-fluid ">
                <img src="assets/images/Backgrounds/loginimg.png" alt="login" class="login-card-img">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="p-3">
                <div class="card mb-0" style="border-radius: 13px;">
                  <div class="insidecard">
                    <form  [formGroup]="forgotForm" (ngSubmit)="forgotFormSubmit()" >
                      <div class="">
                        <div class="brand-wrapper text-center">
                          <img src="assets/images/logo/zendit-logo.jpg" alt="logo" class="logo">
                        </div>
                        <h6 class="login-card-description text-center">Forgot password</h6>
                        <p class="text-center f-11">If you have forgotten your password you can reset it here </p>
                        <div class="form-group row">
                          <div class="col-sm-12">
                            <label for="email" class="control-label">Email address</label><span class="asterisk">*</span>
                            <input type="email" formControlName="email"  id="email" class="form-control" 
                              placeholder="Email address">
                              <div
                              *ngIf="(submitted || forgotForm.controls.email.touched)&& forgotForm.controls.email.errors"
                              class="error-notify">
                              <div *ngIf="forgotForm.controls.email.errors.required" class="text-danger mt-1">
                                This field is required.
                              </div>
                              <div *ngIf="forgotForm.controls.email.errors.email" class="text-danger mt-1">
                                Invalid email format.
                              </div>
                              <div *ngIf="forgotForm.controls.email.errors.incorrect" class="text-danger mt-1">
                               {{forgotForm.controls.email.errors.errmsg}}
                              </div>
                            </div>
                          </div>
                        </div>                 
                        <div class="text-center mt-2">
                          <button class="btn btn-sm btn-block btncolor mb-3"  type="submit"  >Submit</button>
                        </div>
                        <a routerLink="/auth/login" class="forgot-password-link ">Back to login</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</main>