import { Injectable } from '@angular/core';
import{HttpClient}from '@angular/common/http'
import { map } from 'rxjs/operators'
import {environment} from '../../environments/environment';
const baseurl=environment.baseurl;

@Injectable({
  providedIn: 'root'
})
export class LoginApiService {

  constructor(private http:HttpClient) { }

  postLogin(data:any){  
    return this.http.post<any>(`${baseurl}login`, data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  postForgot(data:any){
    return this.http.post<any>(`${baseurl}forgot-password`, data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  postReset(data:any ){
    return this.http.post<any>(`${baseurl}reset-password`,data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  postLogout( ){
    return this.http.get<any>(`${baseurl}logout`)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  postRegister(data:any ){
    return this.http.post<any>(`${baseurl}api/register`,data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  postVerification(data:any ){
    return this.http.post<any>(`${baseurl}api/verify-otp`,data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  getVerification(id:number ){
    return this.http.get<any>(`${baseurl}api/resend-otp/`+id)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
  skipTour(id:any){
    return this.http.get<any>(`${baseurl}skip-tour/`+id)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  getTimeZones(){
    return this.http.get<any>(`${baseurl}get-timezones`)
    .pipe(map((res:any)=>{
      return res;
    }))
  }

  registerCompany(data:any ){
    return this.http.post<any>(`${baseurl}company-register`,data)
    .pipe(map((res:any)=>{
      return res;
    }))
  }
}