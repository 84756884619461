import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment';
const baseurl = environment.baseurl;

@Injectable({
  providedIn: 'root'
})
export class ProjectApiService {

  constructor(private http: HttpClient) { }

  postProject(data: any) {
    return this.http.post<any>(`${baseurl}projects`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  viewProject(id: any) {
    return this.http.get<any>(`${baseurl}project-view/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getTaskStastictis(id: any) {
    return this.http.get<any>(`${baseurl}project-task-statistics/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  listProject(status: any, id: any, userID: any) {
    return this.http.get<any>(`${baseurl}project-list/` + status + `/` + id + '/' + userID)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getProjectTypes(data: any) {
    return this.http.post<any>(`${baseurl}project-types`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getSerarchLocation(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET'
      })
    };
    return this.http.get<any>(`http://maps.google.com/maps/api/geocode/json?address=${data}&key=AIzaSyDsSo1aQWDo5qSnPlRDYkKHV04fU2swgCI`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  postCompanyUser(data: any) {
    return this.http.post<any>(`${baseurl}company-user`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  dropdownCompanyUser(data: any) {
    return this.http.post<any>(`${baseurl}management-users`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  addCustomer(data: any) {
    return this.http.post<any>(`${baseurl}customer`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getCompanyCustomer(data: any) {
    return this.http.post<any>(`${baseurl}company-customers`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  /* step2 */
  templatesDropdown(data: any) {
    return this.http.post<any>(`${baseurl}templates-dropdown`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  templateMilestones(id: any) {
    return this.http.get<any>(`${baseurl}template-milestones/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  poststep2Template(id: any, data: any) {
    return this.http.post<any>(`${baseurl}projects/` + id + `/template-details`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  poststepTemplate(id: any, data: any) {
    return this.http.post<any>(`${baseurl}projects/` + id + `/template-details2`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  getLogdata(id: any) {
    return this.http.get<any>(`${baseurl}task-activities/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getCompanyUser(id: any) {
    return this.http.get<any>(`${baseurl}company-user/` + id)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  updateCompanyUser(data: any, id: any) {
    return this.http.post<any>(`${baseurl}company-user/${id}`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  getCustomer(id: any) {
    return this.http.get<any>(`${baseurl}customer/${id}`)
      .pipe(map((res: any) => {
        return res;
      }))
  }

  updateCustomer(data: any, id: any) {
    return this.http.post<any>(`${baseurl}customer/${id}`, data)
      .pipe(map((res: any) => {
        return res;
      }))
  }
  deleteProject( id: any) {
    return this.http.get<any>(`${baseurl}delete-project/${id}`)
      .pipe(map((res: any) => {
        return res;
      }))
  }



}
