import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/firebase/auth.service';
import { LoginApiService } from 'src/app/shared/login-api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public newUser = false;
  public signup: boolean;
  public user: firebase.User;
  public loginForm: FormGroup;
  public registraionForm: FormGroup;
  public forgotForm: FormGroup;
  submitted = false

  constructor(public authService: AuthService, private fb: FormBuilder,
    public loginService: LoginApiService,
    public toastr: ToastrService, public router: Router) {
      this.forgotForm = fb.group({
        email: [null, [Validators.required, Validators.email]],
      });
     }

  ngOnInit(): void {
  }

  forgotFormSubmit() {
    this.submitted = true
    if (this.forgotForm.valid) {
      this.loginService.postForgot(this.forgotForm.value)
        .subscribe((res: any) => {
          // ////console.log(res)
          let message = res?.message
          if (res?.token) {
            this.router.navigate(['recovery-email'])

            this.toastr.success(message, ' ', {
              timeOut: 3000,
            });
          } else {
            this.toastr.warning(message, ' ', {
              timeOut: 3000,
            });
          }


          //this.forgotForm.reset()
          //this.responseHandler(res);
        },
          error => {
            ////console.log(error)
            let errmsg = (error?.error?.errors?.email);
            this.forgotForm.controls['email']
              .setErrors({ 'incorrect': true, 'errmsg': errmsg })

          })

      // this.router.navigate(['/auth/login'])
    }
  }
}
