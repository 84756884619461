import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  public today: number = Date.now();

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://aus-widget.freshworks.com/widgets/51000003417.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.head, script);
    
    const script1 = this.renderer.createElement('script');
  script1.type = 'text/javascript';
  script1.text = `
    window.fwSettings={
      'widget_id':51000003417
    };
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}();
  `;
  this.renderer.appendChild(document.head, script1);
  }

}
