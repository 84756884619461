<ngx-loading-bar [color]="'#7366ff'" [includeSpinner]="false" [height]="'4px'"></ngx-loading-bar>
<app-loader></app-loader>
<!-- <app-alert></app-alert> -->
<router-outlet>
    
</router-outlet>
<!-- <app-tour></app-tour> -->
<app-tap-to-top></app-tap-to-top>
<!-- <ng-template #openTour let-modal>
    <div class="modal-body modal-padding">
      <div class="card card-height-tour">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <img src="assets/images/logo/zendit-logo.png" style="width: 10%;" alt="" class="img-fluid">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 ">
              <div class="welcome-div text-center">
                <h2 class="welcome f-20">Welcome, <span class="name-class">Matt Davidson<img
                      src="assets/images/Activity/hand.png" class="ml-2" style="width: 4%;" alt=""></span></h2>
                <p class="mb-0">Our mission is to make you more productive.</p>
                <p>This will take only one minute</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="overlay-welcome" *ngIf="!showVideo">
                <img src="assets/images/Backgrounds/welcome-video.jpg" style="width: 100%;" alt=""
                  class="img-thumbnail">
                <div class="overlay-color"></div>
                <a ><img src="assets/images/Backgrounds/play-button.png" (click)="playVideo()" class="welcome-play"
                    alt="play-button"></a>
              </div> -->
              <!-- <iframe *ngIf="showVideo" [src]="videoURL | safe" width="100%" height="500vh" frameborder="0" allowfullscreen
                (click)="call()" webkitallowfullscreen mozallowfullscreen autoplay>
              </iframe> -->
              <!-- <video *ngIf="showVideo" width="100%" height="500" controls>
    <source [src]="videoURL" >
    Your browser does not support the video tag.
  </video>
            </div>
          </div>

        </div>
      </div>
      <hr>
      <button class="btn btncancel-outline float-right" (click)="skiptour()">Skip video</button>
    </div>
  </ng-template> -->