<!-- page-wrapper Start-->
<div [attr.main-theme-layout]="layout.config.settings.layout_type"
  [ngClass]="layout.config.color.mix_background_layout">
  <div class="page-wrapper compact-wrapper" id="canvas-bookmark" [ngClass]="layout.config.settings.sidebar.type">
    <app-header></app-header>
    <!-- Page Body Start-->
    <div class="page-body-wrapper" [ngClass]="layout.config.settings.sidebar.body_type">
      <header class="main-nav" [attr.sidebar-layout]="layout.config.settings.sidebar_setting"
        [class.close_icon]="navServices.collapseSidebar">
        <app-sidebar></app-sidebar>
      </header>
      <div class="page-body">
        <main [@fadeInAnimation]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
          <!-- <button (click)="showTour(openTour)">showTour</button> -->
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
</div>
<app-customizer></app-customizer>
<!-- page-wrapper End-->
<ng-template #openTour let-modal>
  <div class="modal-body modal-padding">
    <div class="card card-height-tour">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-11">
            <img src="assets/images/logo/zendit-logo.png" style="width: 10%;" alt="" class="img-fluid">
          </div>
          <div class="col-sm-1">
            <h5 style="cursor:pointer;" class="modal-close float-right" ngbTooltip="Close" (click)="skiptour()"
              ngbTooltip="Close">
              ×</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 ">
            <div class="welcome-div text-center">
              <h2 class="welcome f-20">Welcome, <span class="name-class">{{userName}}<img
                    src="assets/images/Activity/hand.png" class="ml-2" style="width: 4%;" alt=""></span></h2>
              <p class="mb-0">Our mission is to make you more productive.</p>
              <p>This will take only one minute</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="overlay-welcome" *ngIf="!showVideo">
              <img src="assets/images/Backgrounds/welcome-video.jpg" style="width: 100%;" alt="" class="img-thumbnail">
              <div class="overlay-color"></div>
              <a><img src="assets/images/Backgrounds/play-button.png" (click)="playVideo()" class="welcome-play"
                  alt="play-button"></a>
            </div>
            <!-- <video *ngIf="showVideo" width="100%" height="500vh" controls (ended)="videoEnded()" frameborder="0">
              <source [src]="videoURL" type="video/mp4">
            </video> -->
            <div *ngIf="showVideo">
              <app-tour [height]="height"></app-tour>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btncancel-outline float-right" (click)="skiptour()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>