import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginApiService } from 'src/app/shared/login-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  createAccountForm: FormGroup
  submitted = false

  toggle1: boolean = false;
  toggle2: boolean = false;

  constructor(private fb: FormBuilder,private loginService:LoginApiService,private router:Router,public toaster:ToastrService) {
    this.createAccountForm = this.fb.group({

      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      address: [null, Validators.required],
      password: [null, Validators.required],
      password_confirmation: [null, Validators.required],
      phone_number:[null,[Validators.required,Validators.pattern(("^((\\+91-?)|0)?[0-9]+(.[0-9]+)?$"))]],
      isTerms:[null,Validators.required]
    })
  }

  ngOnInit() {
  }

  createAccountSubmit() {
    this.submitted = true
    if(this.createAccountForm.valid){
      let data={
        first_name:this.createAccountForm.value.first_name,
        last_name:this.createAccountForm.value.last_name,
        email:this.createAccountForm.value.email,
        address:this.createAccountForm.value.address,
        password:this.createAccountForm.value.password,
        phone_number:this.createAccountForm.value.phone_number,
        password_confirmation:this.createAccountForm.value.password_confirmation,
        isTerms:this.createAccountForm.value.isTerms
      }
      this.loginService.postRegister(data)
        .subscribe((res: any) => {
          ////console.log(res)
          let User = res.user;
          let UserString = JSON.stringify(User);
          localStorage.setItem('findmyway', UserString);
          ////console.log(UserString)
          // Swal.fire({
          //   text : "Account created successfully!",
          //   icon: "success",
          // });
          // this.responseHandler(res);
                //  this.router.navigate(['view-floar']);

           this.router.navigate(['/account-verification']);
        },
          error => {
            ////console.log(error)
            let finalError=error.error.errors;
             
            if (finalError?.email) {
              let errmsg = finalError.email ;
              this.createAccountForm.controls['email']
                .setErrors({ 'incorrect': true, 'errmsg': errmsg })
            } 
            else if (finalError?.password) {
              let errmsg = (finalError?.password) ?? 'Your passwords mismatch!';
              this.createAccountForm.controls['password_confirmation']
                .setErrors({ 'incorrect': true, 'errmsg': errmsg })
            }else{
              this.toaster.warning(error)
            }
            // else if (error.error.type == 3) {
            //   let errmsg = (error?.error) ?? 'This user is not authenticated to login! Please contact administrator';
            //   this.loginForm.controls['email']
            //     .setErrors({ 'unauthenticated': true, 'errmsg': errmsg })
            // }
          })
      // this.router.navigate(['view-floar']);
    }
  }

  changeType(input_field_password: any, num: number) {
    if (input_field_password.type == "password")
      input_field_password.type = "text";
    else
      input_field_password.type = "password";

    if (num == 1)
      this.toggle1 = !this.toggle1;
    else
      this.toggle2 = !this.toggle2;
  }

}
