// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "Your Api Key",
    authDomain: "Your Auth Domain",
    databaseURL: "Your Database Url",
    projectId: "Your Project Id",
    storageBucket: "Your StorageBucket url",
    messagingSenderId: "Your Sender Id",

  },
  //new_design server
  // baseurl: 'http://54.69.85.118/api/',
  // image_url: 'http://54.69.85.118/uploads/',

  // // our server
  // baseurl: 'http://44.233.110.170/api/',
  // image_url: 'http://44.233.110.170/uploads/',

  //client server
  baseurl: 'https://staging.zendit.com.au/api/',
  image_url: 'https://staging.zendit.com.au/uploads/',


  // Demo server for testing
  // baseurl: 'https://ishdesigns.com.au/cosmic_demo/api/',
  // image_url: 'https://ishdesigns.com.au/cosmic_demo/uploads/',

  // baseurl: 'http://127.0.0.1:8000/api/',
  // image_url: 'http://127.0.0.1:8000/uploads/',

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
